
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { ElNotification } from "element-plus";
import { Notification } from "@/interfaces/notification/notification.dto";

export default defineComponent({
  name: "Notification",
  computed: mapGetters(["notification"]),
  data() {
    return {
      titles: {
        success: "Успешно",
        error: "Ошибка",
        warning: "Предупреждение",
        info: "Информация",
      },
    };
  },
  watch: {
    "$store.getters.notification": function () {
      const notification: Notification.Dto = this.notification;
      if (notification) {
        ElNotification({
          customClass: "customNotification",
          message: notification.message,
          type: notification.status,
          duration: notification.timeout || 5000,
          title: this.titles[notification.status],
        });
      }
    },
  },
});
